import * as React from 'react'
import Layout from '../components/layout'

const TermsPage = () => {
    return (
    <Layout pageTitle="Algemene Voorwaarden">
        <section className="featured-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-title text-center">
                            <h3 className="text-uppercase">Algemene voorwaarden</h3>
                        </div>
                    </div>
                </div>
                <p>De voorwaarden hieronder gelden voor de huur van lokalen gelegen in “PAND99”, Sint-Gillislaan 99 te 9200 Dendermonde.</p>
                <p>“PAND99” behoort tot PARTNERS@WORK V.O.F.</p>
                <p>Voor elke nieuwe boeking vanaf 1 augustus 2017 volgt u de onderstaande richtlijnen. Elke update vernietigt automatisch de voorwaarden geldend in de vorige versies.</p>
                <p>Heeft u vragen of opmerkingen, dan staat “PAND99” graag tot uw dienst! Contacteer ons schriftelijk op bovenstaand adres of via info@pand99.be</p>
                <p>De huurder verklaart zich akkoord  met deze voorwaarden bij de definitieve reservatie.</p>

                <h4>Rookverbod</h4>
                <p>Vanaf 1 juli 2011 is het rookverbod van toepassing in alle gesloten plaatsen toegankelijk voor het publiek. Het is dus ten strengste verboden te roken in het gehele gebouw, ook wanneer deze ruimtes voor privé gebruik worden gehuurd.Personen die weigeren om het rookverbod na te leven, riskeren een boete.</p>

                <h4>Schade</h4>
                <p>
                    <ol>
                        <li>De lokalen worden  beschouwd als zijnde in perfecte staat. Als de huurder opmerkingen heeft, zal hij deze schriftelijk moeten bekendmaken op het moment van de terbeschikkingstelling van de locatie en moeten deze aanvaard worden door de verhuurder.</li>
                        <li>De verhuurder neemt geen verantwoordelijkheid wegens beschadiging, verlies, diefstal of brand van persoonlijke zaken. Door de gebruikers, huurders zal geen enkel verhaal kunnen uitgeoefend worden ten opzichte van de verhuurder.</li>
                        <li>De huurder mag geen andere bestemming geven aan de ruimte dan die waarvoor de verhuring werd toegestaan. Het onderverhuren van de ruimte is ten strengste verboden.</li>
                        <li>Slechts die lokalen mogen in gebruik genomen worden waarvoor toelating is verleend.</li>
                        <li>De huurder dient zich te houden aan de voorgeschreven wetten van openbare orde.</li>
                        <li>De huurders zijn verantwoordelijk voor alle mogelijke schade, die door hen of door deelnemers of bezoekers van de lokalen, aanhorigheden en de aan hen toevertrouwde uitrusting worden toegebracht. Deze schade zal door de huurders onmiddellijk op eerste verzoek integraal betaald worden.</li>
                        <li>“PAND99” kan niet verantwoordelijk gesteld worden voor het om het even welke schade van lichamelijke of stoffelijke aard naar aanleiding van de toegestane activiteit of van het toegestane gebruik van lokalen en/of uitrusting.</li>
                        <li>Na afloop wordt eventuele schade aan de lokalen,  het gehuurde materiaal in koste gebracht via een schadebeding. Eventuele vastgestelde schade dient meteen gemeld te worden aan het personeel van “PAND99”.</li>
                        <li>Na afloop zorgt “PAND99” voor de schoonmaak van de ruimte. De huurder zorgt voor verwijdering van het afval. Als dit niet gebeurt worden eventuele kosten aangerekend.</li>
                    </ol>
                </p>

                <h4>Brandveiligheid</h4>
                <ol>
                    <li>“PAND99”  is verzekerd voor brandschade.</li>
                    <li>Alle in- en uitgangen naar de zalen en alle brandbestrijdingsmiddelen  moeten op elk ogenblik vrij zijn.</li>
                    <li>Het gebruik van: kaarsen en brandbare materialen is ten strengste verboden.</li>
                    <li>Elke inbreuk of poging tot inbreuk op bovenstaande vermeldingen in zake brandveiligheid zal resulteren in de onmiddellijke stopzetting van de activiteit. De huurprijs, voorschotten en het verbruik zal ook bij stopzetting opeisbaar zijn.</li>
                </ol>

                <h4>Huurbedrag / waarborg</h4>
                <ol>
                    <li>De huurprijs is afhankelijk van de gekozen ruimte.</li>
                    <li>Bij reservatie vanaf 200 euro wordt een voorschot/waarborg van 10 % van het huurbedrag gevraagd. Deze dient via facturatie of via bankoverschrijving te gebeuren.</li>
                </ol>

                <h4>Gebruik website</h4>
                <ol>
                    <li>Wanneer u een boeking plaatst via www.pand99.be dan gaat u een transactie aan met “Pand99” waarbij u de boeking plaatst. U garandeert juridisch bevoegd te zijn om contractuele verbintenissen aan te gaan, en deze website te gebruiken in overeenstemming met deze Algemene Voorwaarden. Ook verklaart u financieel draagkrachtig en handelingsbevoegd te zijn voor uw activiteiten op deze website. U garandeert bovendien dat alle gegevens die door u aangaande het gebruik van deze website worden verstrekt, waar en exact zijn.</li>
                    <li>Misbruik van de boekingsfaciliteiten van deze website kan ertoe leiden dat u de toegang tot deze faciliteiten wordt ontzegd. Zonder enige beperking is enig valse of frauduleuze boeking verboden.</li>
                    <li>“PAND99” houdt zich het recht voor om boekingen met onvolledige, twijfelachtige of ogenschijnlijk verkeerde gegevens van de boekende niet te accepteren. Dit recht geldt ook bij boekingen van personen of registraties die in het verleden regelmatig opgevallen zijn door zogenaamde no shows (niet verschijnen zonder annulering) of niet/niet volledig betaalde rekeningen.</li>
                    <li>“PAND99”  is op geen enkele wijze aansprakelijk voor het om welke reden dan ook niet bereikbaar zijn of het niet werken van de website of een deel of delen van de website. In het geval van onbereikbaarheid of niet werken van de website of een deel of delen van de website, zal “PAND99” er alles aan doen om de website binnen een redelijke termijn weer bereikbaar en/of werkend te maken.</li>
                </ol>

                <h4>Verhuring</h4>
                <ol>
                    <li>U huurt een zaal voor minimum één uur. Voor elk begonnen uur rekenen we een volledig uur aan. Elke zaal heeft een maximale capaciteit en een vaste huurprijs.</li>
                    <li>De  vermelde prijzen zijn exclusief btw.</li>
                    <li>Facturatie van de maandelijkse bedragen  zijn ten laatste betaalbaar 30 dagen na factuurdatum. Wordt deze termijn overschreden, dan wordt van rechtswege en zonder ingebrekestelling interest gevorderd. Deze nalatigheidinterest wordt berekend op basis van de referentie-interestvoet van de Europese Centrale Bank.</li>
                    <li>PARTNERS @ WORK V.O.F. kan bij nalatigheid in de betaling lopende overeenkomsten annuleren of opschorten tot de betaling heeft plaatsgevonden. Dit alles onverminderd het betalen van schadevergoeding door de in gebreke gebleven klant. Bovendien wordt, zonder ingebrekestelling, het openstaande factuurbedrag vermeerderd met 10 %, met een minimum van 25 EUR ten titel van schadebeding voor buitengerechtelijke invorderingskosten en contractuele schade, onverminderd andere relevante invorderingskosten.</li>
                    <li>PARTNERS @ WORK V.O.F. kan de overeenkomst zonder ingebrekestelling verbreken in het geval de klant een gerechtelijk concordaat of uitstel van betaling zou aangevraagd hebben, of in staat van faillissement  of kennelijk onvermogend zou zijn.</li>
                    <li>Bij elke online boeking gaan wij ervan uit dat u de zaal in de standaardopstelling reserveert. Heeft u andere wensen of opmerkingen, noteer die dan gerust bij de rubriek "benodigdheden / opmerkingen" in het online reservatieformulier.</li>
                    <li>Na afloop van uw vergadering (en binnen de gereserveerde tijd) zet u de vergaderzaal terug in de standaardopstelling. Anders rekenen we een forfait aan van € 30,- voor de opkuis van de zaal.</li>
                    <li>Mogen we u vragen de gereserveerde uren na te leven, vaak gebeurt het dat de zaal diezelfde dag zowel vóór als nà u verhuurd is.  Daarom vragen we u vriendelijk de zaal op tijd in gebruik te nemen én zeker op tijd te verlaten.</li>
                    <li>“PAND99” is op geen enkele wijze aansprakelijk voor enige vorm van misverstanden, overboekingen of boekingen die niet juist bij de verhuurder  terecht zijn gekomen.</li>
                </ol>

                <h4>Geen reserverings-, administratie- en/ of boekingskosten</h4>
                <p>“PAND99” rekent geen reserverings-, administratie en/of boekingskosten bovenop de tarieven die ze rekent. Aan het annuleren of wijzigen van een boeking tot 48 uur voor de betreffende boeking zijn eveneens geen kosten verbonden. Voor het annuleren en wijzigen van boekingen binnen 48 uur voor de betreffende boeking zie punt “annuleren”.</p>

                <h4>Wat doet u na gebruik?</h4>

                <p>Na afloop van uw vergadering vragen wij om de gebruikte tassen en glazen te verzamelen op de koffietafel.</p>
                <p>Tafels, stoelen en eventueel ander meubilair zet u op hun oorspronkelijke plaats terug zodat de zaal in de standaardopstelling achterblijft.</p>
                <p>Let erop dat u de beamer en het tv-scherm zeker afsluit na gebruik.</p>
                <p>Vergeet ook niet om het whiteboard proper af te vegen en de gebruikte vellen papier van de flipchart mee te nemen.</p>
                <p>Een eventuele afstandsbediening brengt u terug naar het onthaal. Als u de ruimte verlaat, sluit u alle ramen en deuren en dooft u de verlichting.</p>

                <h4>Catering</h4>
                <h5>Algemeen</h5>
                <ul>
                    <li>In al onze vergaderzalen kan u een eigen broodjesmaaltijd nuttigen. We vragen enkel alle etens- en verpakkingsresten te sorteren in de vuilbakken. </li>
                    <li>“PAND99” kan er ook voor zorgen dat er een broodjesmaaltijd klaar staat voor uw pauzemoment. Frisdranken kunnen op vraag gekoeld en klaargezet worden. U geeft het gewenste aantal bij uw reservatie door. Licht ons tijdig in bij wijzigingen</li>
                </ul>

                <h5>Broodjes bestellen via “PAND99”</h5>
                <ul>
                    <li>Elke bezoeker of zaalgebruiker kan individueel vóór 10u een belegd broodje bestellen. Hij betaalt contant bij de bestelling.</li>
                    <li>Gebeurt het dat een aantal van uw aanwezigen op eigen kosten een broodje willen en anderen niet, dan vragen wij om de bestelling gegroepeerd door te geven vóór 10u. De broodjes worden contant betaald.</li>
                    <li>Wenst u op kosten van uw organisatie broodjes voor uw gasten te voorzien, dan kan u de dag zelf nog het aantal doorgeven: ten laatste om 10u voor de middaglunch en ten laatste om 14u30 voor 's avonds. De facturatie gebeurt achteraf samen met de huur van de zaal. </li>
                </ul>

                <h5>Catering voor groepen groter dan 10 personen</h5>
                <ul>
                    <li>Indien u broodjes of een assortiment van mini-broodjes nodig heeft voor groepen van minimum 10 personen dan geeft u dat door bij uw reservatie. Wij vragen om wijzigingen bij voorkeur 2 dagen vóór de activiteit te melden. De betaling gebeurt via de factuur van de huur van de zaal.</li>
                </ul>

                <h4>Overige</h4>
                <ol>
                    <li>Alle dranken moeten afgenomen worden van “PAND99”.</li>
                    <li>Volgende activiteiten mogen onder geen enkele voorwaarde gehouden worden: studentendopen, studentenacanthussen, activiteiten die vallen onder de wet van 15 juli 1960, gewijzigd door de wet van 9 juli 1973 betreffende de zedelijke bescherming van de jeugd, activiteiten die racistisch, anarchistisch en/of discriminerend van aard zijn of die dergelijke denkpatronen stimuleren, activiteiten die aanzetten tot het gebruik van verboden middelen.</li>
                    <li>Indien een persoon of organisatie boekt onder een valse naam of voorwendsel heeft de verhuurder het recht de verhuring niet te laten doorgaan zonder terugbetaling van de waarborg.</li>
                    <li>Deze overeenkomst valt volledig onder de Belgische wetgeving. Enkel de rechtbanken van het gerechtelijk arrondissement waaronder de verhuurder valt, zijn bevoegd voor geschillen tussen de partijen.</li>
                    <li>De fysieke persoon die het contract tekent in naam van de huurder, als deze laatste een vereniging of maatschappij is, verklaart zich persoonlijk borg te stellen voor de gehele uitvoering van deze overeenkomst en geeft ook, in hoeverre dit nodig zou zijn, zijn solidaire borg voor de perfecte uitvoering van de verplichtingen van de huurder.</li>
                    <li>Dit document maakt vast deel uit van het contract verbonden aan eender welke verhuring van de ruimtes van “PAND99”. De huurder verbindt zich ertoe de voorwaarden hierboven aangehaald te lezen en te respecteren, en bevestigt dat hij deze ontvangen heeft.</li>
                    <li> Alle tarieven zijn vermeld exclusief BTW.</li>
                </ol>

                <h4>Annuleren</h4>
                <ul>
                    <li>Indien een zaal 48 uur op voorhand geannuleerd wordt is dit kosteloos.</li>
                    <li>Bij annulaties minder dan 48 uur op voorhand wordt de zaal volledig aangerekend.</li>
                    <li>Telefonische annulaties dienen steeds per e-mail bevestigd te worden.</li>
                    <li>Bij het annuleren van een reeks boekingen minder dan 1 week voor de start van de reeks wordt 25% van de totale kost aangerekend.</li>
                </ul>

                <h4>Privacy</h4>
                <p>“PAND99” respecteert uw privacy en die van al haar gebruikers en zal zeer zorgvuldig omgaan met de persoonlijke gegevens die op www.pand99.be worden achtergelaten. Bij het boeken van een ruimte wordt u gevraagd persoonlijke gegevens te verstrekken. Deze gegevens zijn nodig om uw boeking te plaatsen, te verwerken en af te ronden. “PAND99” zal uw gegevens nooit zonder uw toestemming aan derden ter beschikking stellen, anders dan derden die betrokken zijn bij het boeken van een ruimte.</p>
		    </div>
	    </section>
    </Layout>
    )
}

export default TermsPage